import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { PrintingLightIcon } from "../../../../assets/icons";
import {
  Label,
  Select,
  SaveButton,
  PageLoader,
} from "../../../../components/basic";
import { ConfirmationDialog, DataTable } from "../../../../components/shared";
import { billItemConst } from "../../../../constants/displayText";
import Info from "./Info";
import Summary from "./Summary";
import {
  cancelPatientBillById,
  getBillDetailById,
  getExcessAmountByPatientId,
} from "../../../../services/billingService";
import { useNavigate, useParams } from "react-router-dom";
import { formula } from "../../../../utils/CalculationUtils";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { RouteUrls } from "../../../../constants/routes";
import PermissionUtils from "../../../../utils/PermissionUtils";
import { RootState } from "../../../../redux/store";
import { getPrintUrl } from "../../../../utils/GeneralUtils";

const { calculateRoundedOff } = formula?.purchaseBill;

const BillItems = () => {
  const { can } = PermissionUtils();
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    SNO,
    itemName,
    consultant,
    description,
    quantity,
    discount,
    tax,
    rate,
    amount,
    receiptNo,
    paymentMethod,
    date,
    remarks,
  } = billItemConst;

  const billColumns: GridColDef[] = [
    {
      field: "SNo",
      flex: 0.5,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={SNO}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.sNo == null ? "" : row.sNo}</Typography>
      ),
      sortable: false,
    },
    {
      field: "itemName",
      flex: 1.5,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={itemName}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.item_name == null ? "" : row.item_name}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "consultant",
      flex: 1.5,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={consultant}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.doctor_name == null ? "" : row.doctor_name}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "description",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={description}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.desc == null ? "" : row.desc}</Typography>
      ),
      sortable: false,
    },
    {
      field: "quantity",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={quantity}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.qty == null ? "" : row.qty}</Typography>
      ),
      sortable: false,
    },
    {
      field: "discount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={discount}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.discount == null
            ? ""
            : `${row.discount} ${row.row_discount_type}`}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "tax",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={tax}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.tax == null ? "" : row.tax}</Typography>
      ),
      sortable: false,
    },
    {
      field: "rate",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={rate}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.price == null ? "" : row.price}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "amount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={amount}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.amount == null ? "" : row.amount}
        </Typography>
      ),
      sortable: false,
    },
  ];

  const paymentColumns: GridColDef[] = [
    {
      field: "receiptNo",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={receiptNo}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.receipt_no == null ? "" : row.receipt_no}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "paymentMethod",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={paymentMethod}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.type == null ? "" : row.type}</Typography>
      ),
      sortable: false,
    },
    {
      field: "date",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={date}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.date_created == null ? "" : row.date_created}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "remarks",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={remarks}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.remarks == null ? "" : row.remarks}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "amount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={amount}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.paid_amt == null ? "" : row.paid_amt}
        </Typography>
      ),
      sortable: false,
    },
  ];

  const initialInfo: any = {
    uhid: "",
    doctor: "",
    mobile: "",
    address: "",
    date: "",
    payee: "",
    paymentType: "",
    billNo: "",
    referredBy: "",
    type: "",
    OPNo: "",
    department: "",
  };

  const [printOptions, setPrintOptions] = useState([]);

  const [info, setInfo] = React.useState(initialInfo);
  const { commonVariables } = useSelector(
    (state: RootState) => state?.appConfiguration
  );

  const initialBillingSummary: any = {
    tax: "",
    total: "",
    billTotal: "",
    discount: "",
    roundedOff: "",
    adjustment: "",
    netAmount: "",
  };

  const [billingSummary, setBillingSummary] = React.useState(
    initialBillingSummary
  );

  const initialPaymentSummary: any = {
    excess: "",
    advance: "",
    overAllDue: "",
    creditLimit: "",
  };

  const [paymentSummary, setPaymentSummary] = React.useState(
    initialPaymentSummary
  );

  const [billsList, setBillsList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getBillDetailById(id)
        .then((res: any) => {
          if (res.data) {
            const { bill, bill_items, billpayments } = res.data;
            setInfo({
              ...bill,
              rdType: bill.ip_no ? "IP" : bill.op_no ? "OP" : "Counter sales",
            });
            setBillsList(
              bill_items.map((ele: any, index: number) => ({
                ...ele,
                sNo: index + 1,
              }))
            );
            setPaymentList(billpayments);
            setBillingSummary({
              tax: bill.tax_amt,
              total: bill.total_amt,
              billTotal: bill.paid_amt,
              discount: bill.discount_amt || 0.0,
              roundedOff:
                calculateRoundedOff({
                  bill_total: bill?.total_amt,
                  bill_discount_amount: bill?.discount_amt,
                }) || 0,
              adjustment: bill.adj_amt,
              netAmount: bill.net_total_amt,
            });

            if (bill.patient_id) {
              getExcessAmountByPatientId({ patient_id: bill.patient_id })
                .then((res: any) => {
                  if (res.data) {
                    setPaymentSummary((prevState: any) => ({
                      advance: res.data.advance,
                      creditLimit: res.data.creditlimit,
                      excess: res.data.excess,
                      overAllDue: res.data.overalldue,
                    }));
                  }
                })
                .catch((err) => console.log("error", err));
            }
          }

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [id]);

  const handleConfirmCancel = () => {
    if (isOpenCancelModal) {
      setIsCancelLoading(true);
      cancelPatientBillById(id)
        .then((res: any) => {
          if (res) {
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          }
          setIsOpenCancelModal(false);
          setIsCancelLoading(false);
          navigate(`${RouteUrls.billingUrl}${RouteUrls.billingListUrl}`);
        })
        .catch((err: any) => {
          console.log("err", err);
          setIsCancelLoading(false);
          if (err?.response?.data?.errors) {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.errors,
              })
            );
          }
        });
    }
  };

  const handlePrintChange = (event: any) => {
    const { name, value } = event?.target;
    setBillingSummary((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    const pdfUrl = getPrintUrl(`billing/print_patient_bill/${id}/${value}`);
    const queryParams = new URLSearchParams({
      url: pdfUrl,
    }).toString();
    window.open(`${RouteUrls?.printUrl}?${queryParams}`, "_blank");
    // navigate(`${RouteUrls?.printUrl}?${queryParams}`);
  };
  useEffect(() => {
    if (commonVariables?.billing_print_formats) {
      setPrintOptions(commonVariables?.billing_print_formats);
    }
  }, [commonVariables?.billing_print_formats]);

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <Grid>
          <Info info={info} />
          <Grid sx={{ m: "20px 0px" }}>
            <DataTable
              columns={billColumns}
              rows={billsList}
              tableOnly={true}
              customizedTable={true}
            />
          </Grid>
          <Summary billingSummary={billingSummary} />
          <Grid sx={{ display: "flex", flexDirection: "column", m: "20px 0" }}>
            <Typography
              variant="h3"
              sx={{
                color: "primary.main",
                m: "20px 0",
              }}
            >
              Payment Method
            </Typography>
            <DataTable
              columns={paymentColumns}
              rows={paymentList}
              getRowId={(row: any) => `${String(row.id)}`}
              tableOnly={true}
              customizedTable={true}
            />
          </Grid>
          <Summary paymentSummary={paymentSummary} />
          <Grid
            container
            rowGap={1}
            sx={{
              display: "flex",
              alignItems: "center",
              m: "20px 0px",
            }}
          >
            <Grid item xs={12} sm={12} md={6} xl={4}>
              <SaveButton
                buttonText="Back"
                sx={{ width: "100px", height: "42px" }}
              />
            </Grid>
            {can("billing_print") && (
              <Grid item xs={12} sm={12} md={6} xl={4}>
                <Select
                  label="Select Print Size"
                  placeholder={<PrintingLightIcon />}
                  name="discountType"
                  sx={{ width: "306px" }}
                  width="306px"
                  options={printOptions}
                  onChange={handlePrintChange}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6} xl={4}>
              {can("billing_cancel") &&
                !info.is_cancel &&
                Number(info.paid_amt) === 0 && (
                  <Box
                    sx={{
                      color: "#fff",
                      backgroundColor: "#f08f00",
                      borderColor: "#c27400",
                      boxShadow:
                        "0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12)",
                      padding: "12px 30px",
                      margin: "0.3125rem 1px",
                      fontSize: ".75rem",
                      fontWeight: 400,
                      lineHeight: 1.428571,
                      cursor: "pointer",
                      borderRadius: "30px",
                      mr: "10px",
                      width: "150px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => setIsOpenCancelModal(true)}
                  >
                    X Cancel
                  </Box>
                )}
            </Grid>
          </Grid>
          <ConfirmationDialog
            open={isOpenCancelModal}
            title={"Do you want to cancel the bill?"}
            confirmText="Yes"
            handleClick={handleConfirmCancel}
            onClose={() => setIsOpenCancelModal(false)}
            loading={isCancelLoading}
          />
        </Grid>
      )}
    </>
  );
};

export default BillItems;
